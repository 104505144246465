<template>
    <div>
        <b-overlay :show="loading" variant="transperant" blur="5px" opacity="1" spinner-variant="primary">
            <!-- Stats Card Horizontal -->
            <b-row id="links">
                <b-col lg="3" sm="6">
                    <statistic-card-horizontal icon="SendIcon" :statistic="user.account.posts_quota_monthly" statistic-title="POSTS" />
                </b-col>
                <b-col lg="3" sm="6">
                    <statistic-card-horizontal icon="MessageSquareIcon" color="success" :statistic="user.account.comments_quota_monthly"
                        statistic-title="COMMENTS" />
                </b-col>
                <b-col lg="3" sm="6">
                    <statistic-card-horizontal icon="LinkIcon" color="danger" :statistic="user.account.urls_quota_monthly"
                        statistic-title="LINKS" />
                </b-col>
                <b-col lg="3" sm="6">
                    <statistic-card-horizontal icon="UserIcon" color="warning" :statistic="user.account.profiles_quota_monthly"
                        statistic-title="SOCIAL PROFILES" />
                </b-col>
            </b-row>

            <b-row>
                <b-col md="4" sm="6">
                    <statistic-card-with-line-chart icon="AwardIcon" color="warning" :statistic="user.account.brands_quota_monthly"
                        statistic-title="BRANDS"
                        :chart-data="[{ name: 'brands', data: [150, 200, 125, 225, 200, 250] }]" />
                </b-col>
                <b-col md="4" sm="6">
                    <statistic-card-with-line-chart icon="UsersIcon" :statistic="user.account.members_quota_monthly" statistic-title="MEMEBERS"
                        :chart-data="[{ name: 'memebers', data: [150, 200, 125, 225, 200, 250] }]" />
                </b-col>
                <b-col md="4" sm="6">
                    <statistic-card-with-line-chart icon="Share2Icon" color="success" :statistic="user.account.channels_quota_monthly"
                        statistic-title="CHANNELS"
                        :chart-data="[{ name: 'channels', data: [150, 200, 125, 225, 200, 250] }]" />
                </b-col>
            </b-row>
        </b-overlay>
    </div>
</template>

<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import { BCard, BCardText, BLink, BRow, BCol, BOverlay } from 'bootstrap-vue'
import axios from 'axios'

export default {
    components: {
        StatisticCardWithLineChart, StatisticCardHorizontal,
        BCard, BCardText, BLink, BRow, BCol, BOverlay,
    },
    data(){
        return {
            loading: true,
            user: {
                account: {
                    brands_quota_monthly: 0,
                    channels_quota_monthly: 0,
                    comments_quota_monthly: 0,
                    members_quota_monthly: 0,
                    posts_quota_monthly: 0,
                    urls_quota_monthly: 0,
                    profiles_quota_monthly: 0,
                }
            }
        }
    },
    mounted(){
        this.getUserInfo();
    },
    methods: {
        async getUserInfo(){
            await axios.get('/me/')
            .then(res => {
                this.loading = false
                console.log(res);
                this.user = res.data
                localStorage.setItem('brands_quota_monthly', res.data.account.brands_quota_monthly)
                localStorage.setItem('channels_quota_monthly', res.data.account.channels_quota_monthly)
                localStorage.setItem('comments_quota_monthly', res.data.account.comments_quota_monthly)
                localStorage.setItem('members_quota_monthly', res.data.account.members_quota_monthly)
                localStorage.setItem('posts_quota_monthly', res.data.account.posts_quota_monthly)

                localStorage.setItem('planid', res.data.account.plan.id)
                localStorage.setItem('planname', res.data.account.plan.name)

                this.$store.commit('auth/setPlan',
                    {
                        'id': res.data.account.plan.id,
                        'name': res.data.account.plan.name,
                    }
                )
            })
            .catch(error => {
                console.log(error);
            })
        }
    }
}
</script>

<style></style>